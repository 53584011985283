/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: white;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  margin: 0;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: 0.5rem;
  margin-bottom: 0.4rem;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}
