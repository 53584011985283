// ***************** Add New space modal css *******************
.add-space-modal {
  height: 28.813rem;
  transform: translateY(10%);
}
.ddl-space {
  margin-bottom: 1.613rem;
}
.input-space {
  margin-bottom: 1.613rem;
}
.select_field__option--is-selected,
.select_field__option--is-focused,
.select_field_with_label__option--is-selected,
.select_field_with_label__option--is-focused {
  background-color: #ffd3c9 !important;
  color: #646464 !important;
  font-size: 0.938rem;
}
.select_field__option,
.select_field_with_label__option {
  color: #646464 !important;
  font-size: 0.938rem !important;
}

// ***************** Add New space from building *******************

.add-space::-webkit-scrollbar {
  display: none;
}
.add-space {
  height: 88vh;
  overflow-y: scroll;
  padding-top: 1rem;

  .add-space-label {
    font-size: 0.938rem !important;
    line-height: normal;
    text-align: left;
    color: #e89a89;
    text-decoration: underline;
    cursor: pointer;
  }

  .select_field_with_label__placeholder {
    color: #c2c2c2 !important;
    font-size: 0.938rem !important;
  }
}

.delete_space {
  margin-top: 2rem;
  margin-bottom: 4rem;
  label {
    height: 1rem;
    color: #ff5f05;
    margin: 1.375rem 57.625rem 0.25rem 0.063rem;
    display: flex;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  span {
    font-size: 0.75rem;
    color: #a9a9a9;
  }
}
.form_title {
  position: relative;
  top: 20px;
  width: 50%;
}

input[type='file'] {
  -webkit-appearance: none;
  text-align: left;
  -webkit-rtl-ordering: left;
}
input[type='file']::before {
  content: 'Upload';
  float: right;
  color: #e89a89;
  font-size: 0.938rem;
  text-decoration: underline;
  cursor: pointer;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
  float: right;
}

.file_upload {
  padding-top: 12px !important;
}

.space_modal {
  width: 31.813rem;
  height: 15.188rem;
}
