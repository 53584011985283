.block {
  display: flex;
}
.whitelabel-model-width {
  text-align: center;
  width: 76.313rem;
  height: 34.125rem;
  transform: translateY(10%);
}
.cross_icon {
  float: left;
  position: relative;
  top: 0.5rem;
}
