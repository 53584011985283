.account_email {
  width: 33.75rem;
  height: 19.125rem;
  padding: 1.935rem 2.375rem;

  .email_header {
    color: #5d5d5d;
    font-size: 1.125rem;
    height: 1.375rem;
    font-family: 'brandon-regular';
    font-weight: 500;
  }
  .email_sub_header {
    color: #646464;
    font-size: 1rem;
    height: 1.375rem;
    font-family: 'brandon-regular';
    font-weight: 500;
  }
  .update_button {
    width: 28.813rem;
    margin-top: 2rem;
    text-align: center;
    display: block;
  }
  .form_class {
    padding-top: 2rem;
  }
  .color_red {
    color: #bf2200 !important;
  }
  .email_exist_header_1 {
    color: #646464;
    font-size: 1rem;
    padding: 1rem;
    text-align: center;
    display: block;
  }
  .email_exist_header_2 {
    @extend .email_exist_header_1;
    margin: 0;
  }
  .yes_merge {
    margin-top: 0 !important;
  }
}

//****************** Account Footer *******************
.account_footer {
  height: 5.5rem;
  display: inline-flex;
  justify-content: flex-end;
  padding-right: 1.2rem
}
.users_count {
  line-height: 5.5rem;
  padding-left: 3.8rem;
  color: #646464;
  padding-right: 19rem;
}
.max-content {
  width: max-content;
}
.sendlink {
  padding-top: 1rem;
  width: 52rem;
  text-align: end;
  .span_text {
    color: #646464;
    display: inline-flex;
    padding-right: 1rem;
  }
  .dropdown_section {
    display: inline-flex !important;
    width: 13.75rem;
    text-align: left !important;
  }
}
.footer_button {
  width: 6rem !important;
  display: block !important;
  margin-top: 1.2rem !important;
  &:disabled {
    background-color: #d2d2d2;
  }
}
