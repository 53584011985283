.tabList_wrapper {
  height: 80vh;
  padding-bottom: 4rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.task-list-item {
  // border: dashed 1px #646464;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #ebebeb;
  border-radius: 10px;
  padding: 1rem;
  line-height: 2rem;
  cursor: pointer;
  .p_title {
    width: -webkit-fill-available;
    color: #646464;
    font-size: 1.125rem;
  }
  .p_timeOfTask {
    width: -webkit-fill-available;
    text-align: right;
    color: #646464;
  }
  .p_vendor {
    font-size: 0.938rem;
    color: #646464;
  }
  .p_description {
    font-size: 1rem;
    color: #646464;
  }
  .a_link {
    font-size: 0.938rem;
    color: #646464;
  }
  .a_link:hover {
    text-decoration: underline;
    color: #e89a89;
  }
  .p_templateName {
    font-size: 0.75rem;
    color: #646464;
    position: relative;
    top: 0.61rem;
    background-color: #fcefd0;
    width: 4.813rem;
    border-radius: 4px;
    height: 1.313rem;
  }
}
.remove-template-text {
  color: #529092;
  font-size: 0.86rem;
  font-weight: 500;
}

.allservice-elements {
  .select_field__value-container.select_field__value-container--is-multi.css-g1d714-ValueContainer {
    min-height: 5.5rem;
    align-items: baseline;
  }
}
.remove-template-status {
  border-radius: 4px;
  background-color: #fcefd0;
  height: 2.313rem;
  padding: 0.5rem;
}
.vendor-not-assigned {
  color: #a9a9a9;
  font-size: 1rem;
}
.add_task_list {
  border: solid 1px #e89a89;
  background-color: white !important;
  color: #e89a89 !important;
  float: none !important;
  margin: 1rem;
}
.all-vendor-elements {
  .css-yk16xz-control {
    .css-g1d714-ValueContainer {
      min-height: 5.5rem !important;
      align-items: baseline;
      overflow-y: scroll;
      border-radius: 20px;
    }
  }
}
.disable-input-fields {
  background-color: #f3f3f3 !important;
}
.h1_custom {
  height: 2.188rem;
  margin: 2rem 3rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #3f3f3f;
}
.add_to_list {
  float: none !important;
  margin: 2rem 3rem;
}
.delete_task {
  float: right;
  position: relative;
  bottom: 8px;
  cursor: pointer;
}
.title_error {
  left: 10rem;
}
.w-t {
  width: 13rem;
}

////////////////////// Dropdown Placeholder ///////////////////
.select_field__placeholder {
  color: #c2c2c2 !important;
}

////////////////////// Input Placeholder ///////////////////
.input_text::placeholder {
  color: #c2c2c2 !important;
}
