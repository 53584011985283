//****************** Building configuration ********************
.event_setup {
  position: relative;
  overflow-y: hidden;
  &::-webkit-scrollbar,
  & textarea::-webkit-scrollbar {
    display: none;
  }
  .building_configuration {
    padding: 2.125rem 1.313rem 2.125rem 1.375rem;
    background-color: #fbfbfb;
    .search-building {
      height: 3rem;
      --bg-opacity: 1;
      background-color: #fff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      display: flex;
      align-items: center;
      border-radius: 10px;
      border-width: 1px;
      border-color: #bbb;
      width: 37.5rem;
      height: 3.375rem;
      font-family: 'brandon-regular';
    }
    .heading_label {
      font-size: 1.5rem;
      font-weight: 500;
      color: #3f3f3f;
      font-family: 'brandon-regular';
    }
    .add_building_button {
      width: 100%;
      height: 3rem;
      border: solid 1px #838383;
      background-color: #ffffff;
      color: #646464;
    }
    .add_buildings_button {
      @extend .add_building_button;
      width: 22.8125rem;
    }
    .pd-wrap {
      padding: 1rem 2.7rem 3rem 4.563rem;
    }
    .select_field_width {
      width: 37.5rem;
      cursor: pointer;
    }
    .pb-btns {
      padding-bottom: 2.938rem;
    }
    .no_buildings_selected {
      position: relative;
      top: 2rem;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #c2c2c2;
    }
    .px-button {
      padding-left: 1.438rem !important;
      padding-right: 1.125rem !important;
    }
  }
  .visibility-select .select_field__indicator,
  .select-title .select_field__indicator {
    display: block !important;
  }
  .setup_event,
  .streaming_setting {
    padding: 2.125rem 1.313rem 2.125rem 1.375rem;

    .setup_description {
      font-size: 1rem;
      color: #646464;
      line-height: 1;
    }
    .edit_input {
      width: 47.3rem;
    }
    .disable {
      background-color: #fbfbfb;
    }
  }
}
.pre-recorded-label {
  flex-grow: 0;
  font-family: 'brandon-regular';
  font-size: 1rem;
  text-align: left;
  color: #646464;
  position: relative;
  top: 1.0625rem;
  left: 0.563rem;
}
.prerecorded input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3125rem;
  border: 0.0625rem solid #bbbbbb;
}
.prerecorded input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  height: 0;
}

.space_disable {
  background-color: #f3f3f3 !important;
  pointer-events: none;
}
.placeholder_color {
  color: #c2c2c2 !important;
}
// ***************** Single Event only ****************
.event-setup-right {
  max-height: 80vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

//********************* Invitees only ******************
.horizontal {
  height: 0.063rem;
  margin: 6rem 0 2rem;
  background-color: #7d7d7d;
}

.border_none {
  border: none !important;
}

.tagbox_position {
  position: relative;
  top: 2rem;
  line-height: 3rem !important;
  white-space: nowrap;
  word-break: unset;
}
.tagbox_position_selected_building {
  position: relative;
  top: 0;
  line-height: 3rem !important;
  white-space: nowrap;
  word-break: unset;
}

.tagbox_building {
  @extend .tagbox_position;
  top: 0;
}

.invitees_save {
  position: relative;
  top: 7rem;
  margin: 0;
  float: none;
}

.region_name {
  position: absolute;
  line-height: 3;
}
.option_text_wd {
  width: 83%;
}
.selected_buildings_box {
  min-height: 30rem;
  max-height: 30rem;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 4rem;
}
.region_name_time {
  line-height: 3;
}
.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}
.recurring-date {
  border-radius: 25px;
  border: 1px solid #3f3f3f;
  width: 0.938rem;
  height: 0.938rem;
  font-size: 0.675rem;
  text-align: center;
}

//*********************** Recurring Event Setup *******************
.recurring_wrapper {
  height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.unselected_days {
  border: solid 1px #7e7e7e;
  background-color: #ffffff !important;
  color: #7e7e7e !important;
}

.select_days {
  background-color: #e89a89;
  color: #ffffff;
  border-radius: 40px;
}

.recurring_building {
  height: auto !important;
}
.recurring_title {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  border-top: solid 1px rgba(0, 0, 0, 0.05);
}
.recurring_ls {
  background-color: #fcfcfc;
  padding: 2.125rem 1.313rem 2.125rem 1.375rem;
}
.weekday_section {
  &:last-child {
    padding-bottom: 4rem;
  }
}

// ********************** Footer Button *********************

.recurring_footer {
  z-index: 0;
}

.recurring_continue {
  width: 10rem !important;
  padding: 0.813rem 1rem !important;
  border-radius: 40px !important;
  border: solid 1px #e89a89 !important;
  background-color: #ffffff !important;
  color: #e89a89 !important;
}

.footer_left_button {
  float: left !important;
  margin-left: 13rem !important;
  border: 1px solid #646464;
  background-color: #ffffff !important;
  color: #646464 !important;
}

.event_action__control {
  width: 10rem !important;
}

.fileUpload {
  .input_text {
    border-top-width: 1px;
    border-left-width: 1px !important;
    border-bottom-width: 1px;
    border-color: #d3d4d4;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}
