.Chat {
  width: 100%;
  height: 72vh;
  border: solid 0.063rem #f3ddd8;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.Chat .MessageList {
  box-sizing: border-box;
  flex: 1;
  margin: 0.625rem;
}
.adminChat .MessageList {
  box-sizing: border-box;
  flex: 1;
  padding: 1rem 2rem;
}
.adminChat .MessageForm {
  padding: 0 2rem;
  flex: 0 3.75rem 3.125rem;
  margin-bottom: 1rem;
}
.Chat .MessageForm {
  margin: 1.25rem;
  box-sizing: border-box;
  flex: 0 0 30.313rem;
}

.author-circle {
  border-radius: 50%;
  background-color: #dfba60;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  &::after {
    content: '';
    padding-bottom: 100%;
  }
}
.message-content {
  display: inline;
}
.Message.log {
  color: #bbb;
}

.Message .author {
  font-weight: bold;
  color: #888;
  font-size: 0.875rem;
  margin-bottom: 0;
}
.admin-author {
  font-size: 0.875rem;
  color: #cd5d44;
  margin-bottom: 0;
}
.Chat .admin-author {
  text-align: left;
}

.admin-message {
  text-align: right;
  overflow-wrap: anywhere;
}
.user-message {
  overflow-wrap: anywhere;
}

.edit-icon {
  display: none;
  margin: 0.188rem;
}

.Message.me {
  &:hover {
    .message-body {
      background-color: #f9f9f9;
      .edit-icon {
        float: right;
        display: block;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.Message.me .author {
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 0;
}
.Message {
  font-size: 0.875rem;
  color: #3f3f3f;
  margin: 0.625rem 0;
  a {
    color: #4285f4;
  }
}

.MessageForm {
  display: flex;
  border: 0;
}
.transparency {
  opacity: 0.4;
}
.MessageForm .input-container {
  flex: 1;
}

.adminChat .MessageForm .button-container {
  flex: 0.3;
  margin: 0.063rem 0.063rem 0.063rem 0;
  width: auto;
}

.MessageForm input {
  &::placeholder {
    font-size: 0.75rem;
    color: #a9a9a9;
  }
  width: 100%;
  border-radius: 0.625rem;
  border: solid 0.063rem #c4ccc4;
  background-color: #ffffff;
  padding: 0 0 0 0.625rem;
}

.MessageList {
  overflow-y: auto;
  padding: 0.25rem;
  &::-webkit-scrollbar {
     display: none;
   }
}

.adminChat .MessageForm .chat-submit-btn {
  font-size: 1rem;
  font-weight: 500;
  color: #fefefe;
  background-color: #f36b7f;
  border-radius: 2rem;
  padding: 0 1rem;
}
.user-chat-heading {
  height: 2.938rem;
  display: flex;
  align-items: center;
  font-family: 'AvenirNext-DemiBold';
  font-size: 1rem;
  color: #686868;
  padding: 10.188rem 0 0 20.188rem;
}
.edit-form {
  @extend .MessageForm;
  flex: 0;
  margin: 0 !important;
  padding: 0 !important;
  height: 2.5rem;
  input {
    width: 98%;
  }
  .chat-submit-btn {
    width: 100%;
  }
}

.adminChat {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  border: solid 0.063rem #e3e3e3;
  height: 60vh;
}
.message-layout {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: left;
}

.message-body {
  margin-left: 0.625rem;
  width: 89%;
}
.chat-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  img {
    width: 6.25rem;
  }
  p {
    margin: 0;
    font-size: 0.75rem;
    font-weight: 500;
  }
}
.chat-user-name {
  margin: auto 1.875rem;
  background-color: transparent;
  p {
    font-size: 1rem;
    color: #686868;
  }
  label {
    display: block;

    font-size: 0.875rem;
    font-weight: 100;
    color: #686868;
  }
  input {
    width: 100%;
    border: #ebebeb 0.063rem solid;
    outline: none;
    border-radius: 0.313rem;
    height: 2rem;
    padding-left: 0.5rem;

    font-size: 0.875rem;
    color: #686868;
  }
  .chat-submit-btn {
    background-color: #e89a89;
    color: #fff;
    width: 100%;
    margin-top: 1.875rem;
    padding: 0.438rem;
    outline: none;
    border: 0;
    border-radius: 0.313rem;
  }
}
.chat-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 1rem;
  color: #686868;
}
.emoji-btn {
  float: right;
  border: none;
  margin: 0 0.625rem;
  cursor: pointer;
  font-size: 20.25rem;
}

.chat-heading-content {
  height: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem;
}
