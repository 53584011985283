// **************** Company Delete popup ****************
.delete_company_modal {
  width: 33.813rem;
  height: 15.313rem;
  text-align: center;
}
.header_style {
  font-size: 1.25rem !important;
}
.sub_header_style {
  font-size: 1rem !important;
  margin: 1rem 1.5rem 0 1.5rem;
  display: flex;
}
.company_button_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
