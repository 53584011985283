.add-service-modal {
  width: 57.125rem;
  height: 23.375rem;
  transform: translateY(20%) !important;
  padding: 2.935rem 2.375rem !important;
}
.service_element {
  margin-top: 0.25rem;
  height: 3.1rem;
}
.p_service {
  font-size: 0.938rem;
}

.addsetup_section {
  position: relative;
  height: 80vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.drop_input_label {
  width: 14.2rem !important;
}
.documents_label {
  width: 12rem !important;
}
.text_area_label {
  width: 12rem !important;
}
.select_field_with_label__value-container,
.select_field_with_label__value-container--has-value {
  padding: 2px 8px 2px 15px !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4.25rem;
  box-shadow: -2px -4px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;

  button {
    margin: 11px;
  }
}
//***************** Vendors Tab  **************
.p_vendorspage {
  padding: 3rem 2.078rem;
  .service-title {
    font-size: 1.125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5d5d5d;
  }
}

.multi_package {
  @extend .p_vendorspage;
  padding: 3rem 0;
  &:first-child {
    padding: 0;
  }
}
.multi-element-box {
  padding: 0 2.078rem;
}

.category-tags {
  padding: 0 0 0 1.25rem !important;
}

//***************** Element Listing **************

.element_section {
  padding-top: 2rem;
  height: 80vh;
  overflow-y: auto;
  padding-bottom: 10rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

//*************** Pricing section ***************
.pricing_section {
  padding-top: 4rem;
  padding-bottom: 6rem;
  height: 80vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

//*************** Tab sections ***************
.bottom-line {
  border-bottom-width: 2px;
  border-color: #f6f6f6;
}
.tab_list {
  color: #c2c2c2;
}
.tab_list_active {
  color: #646464;
}
.event_vendor_active {
  color: #529092;
  img {
    height: 1.4rem;
  }
}

//*************** Service Listing Page **************
.listing_col_last {
  width: 9rem;
  padding-left: 2.8rem !important;
  background-color: white;
  color: #646464;
  cursor: not-allowed;
  pointer-events: none;
}
.multi-element-heading {
  width: 100%;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  height: 3.75rem;
  display: flex;
  margin-left: 0.5rem;
}

//**************** Delete Popup ****************
.task_list_model {
  width: 31.813rem;
  height: 13.563rem;
}

.delete_service {
  width: 31.813rem;
  height: 13.563rem;
}

.close_button {
  width: 11.25rem !important;
  border-radius: 40px !important;
  border: solid 1px #e89a89;
  background: white !important;
  color: #e89a89 !important;
  display: block !important;
  margin-right: 2rem;
  margin-top: 2rem;
}
.yes_delete {
  width: 11.25rem !important;
  border-radius: 40px !important;
  background-color: #e89a89 !important;
  color: white !important;
  display: block !important;
  margin-top: 2rem;
}

.element_last_column {
  width: 9rem;
  text-align: center;
  color: #646464;
  background-color: white;
  font-family: 'brandon-regular';
}
.vendor-section-service {
  position: relative;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 4rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

//****************** Vendor requirment *************
.subheader_class {
  font-size: 0.75rem;
  color: #7d7d7d;
  padding-left: 0.5rem;
}

//**************** Select-time tab ****************

.event-time-zone-color {
  color: #646464 !important;
}

//***************** Footer Loader ***************
.footer-loader {
  top: 60% !important;
  position: relative !important;
  left: 47% !important;
}

// ************** Production team ***************
.delete-prod-team {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  label {
    color: #c4c4c4;
    font-size: 0.875rem;
    cursor: pointer;
    text-decoration: underline;
  }
}
