.white-label-button {
  margin-top: 0.25rem;
  border-radius: 10px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid #cbd5e0;
}

.white-label-input {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
