.add-cluster-modal {
  height: 28.813rem;
  transform: translateY(10%);
}
.label {
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: 500;
  color: #5d5d5d;
  position: relative;
  bottom: 20px;
}
.control-space {
  padding-bottom: 1.613rem;
}
.input-control-space {
  margin-bottom: 1.613rem;
}
.staff-error {
  position: absolute;
  font-size: 1rem;
  color: #bf2200;
  top: 50px;
  text-align: center;
  display: block;
  width: 90%;
}

// ***************** Add building staff form *****************

.staff-section {
  max-height: 87vh;
  overflow-y: scroll;
  padding-top: 2rem;
  &::-webkit-scrollbar {
    display: none;
  }
  .staff-add {
    position: absolute;
    top: 7rem;
    right: 0;
    margin-right: 2.5rem;
  }
}

// ******************** Custom Checkbox ******************
.checkbox input[type='checkbox'] {
  display: block;
}
.checkbox,
.checkbox::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 5px;
}

.save-goback {
  position: absolute;
  top: 6rem;
  right: 0;
  margin-right: 2.5rem;
}

.table_col_building {
  width: 70%;
  background-color: white;
  color: #529092;
  padding: 1.31rem 1.25rem;
}
.table_col_dashboard {
  padding-left: 4rem;
  background-color: white;
}

.error-info {
  top: auto;
  text-align: center;
  left: 27%;
  position: absolute;
  font-size: 1rem;
  color: #bf2200;
}

.table_cell {
  width: 20rem;
}
