//******************** Create Event Popup *******************
.add_event_modal {
  width: 100%;
  height: 100%;
  transform: translateY(0%) !important;
  border-radius: 0;
}
.main_section::-webkit-scrollbar {
  display: none;
}

.main_section {
  display: block;
  height: 90vh;
  overflow-y: scroll;
  .header_label {
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    color: #3f3f3f;
    position: relative;
    text-align: center;
  }
  .subheader_label {
    text-align: center;
    color: #7d7d7d;
    font-size: 0.938rem;
    margin-bottom: 2rem;
  }
  .input-w {
    width: 23rem;
  }
  button {
    display: block;
  }
}

.auto-margin {
  margin: 0 auto;
  padding-right: 0.625rem;
}

.disable-package {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #f2f2f2;
}

.eventType {
  width: 11.25rem;
  height: 3rem;
  padding: 0.75rem 0.313rem 0.75rem 0.625rem;
  color: #ffffff;
  border-radius: 10px;
  border: solid 1px #d3d4d4;
  display: inline-flex;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    border: solid 1px #646464;
    background-color: #646464;
  }
  img {
    display: inline-flex;
    height: 1.5rem;
    padding-right: 10px;
  }

  .img-recurring {
    height: 1rem;
    margin-top: 0.2rem;
  }
}
.disable-eventType {
  pointer-events: none;
}
.eventTypeActive {
  outline: none;
  border: solid 1px #646464;
  background-color: #646464;
}
.eventTypeIdle {
  color: #646464;
}
.disable-event-type {
  background-color: #f2f2f2;
}

.date-picker-width {
  width: 10.298rem !important;
}

//************************ TopNav Event action Dropdown ********************
.event_event_action__control {
  width: 9rem;
  border: none !important;
  box-shadow: 0 0 0 0.0625rem transparent !important;
  &:focus {
    outline: none;
  }
  .event_event_action__indicator-separator,
  .schedule_event_action__indicator-separator {
    display: none;
  }
}
.event_event_action__menu,
.schedule_event_action__menu {
  width: max-content !important;
  border-radius: 0.5rem !important;
  margin: 0 !important;
}
.schedule_event_action__control {
  @extend .event_event_action__control;
  width: 11rem;
}

.event_event_action__option--is-focused,
.schedule_event_action__option--is-focused {
  background-color: #ffd3c9 !important;
}

.event_event_action__option:hover,
.schedule_event_action__option:hover {
  background-color: #ffd3c9 !important;
}

.event_event_action__menu-list,
.schedule_event_action__menu-list {
  padding: 0 !important;
  border-radius: 0.5rem !important;
}
.event_event_action__option,
.schedule_event_action__option {
  display: flex !important;
  column-gap: 0.5rem;
  align-items: center;
}

.header .publish_button {
  width: 10rem !important;
}
//*************************** Vendor Assignment ************************

.vendor_assignment {
  position: relative;
  overflow-y: scroll;
  max-height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }

  .vendorsettings {
    height: 100vh;
    padding: 2.125rem 1.313rem 2.125rem 1.375rem;
    background-color: #fbfbfb;

    .css-yk16xz-control.select_field__control {
      height: 5.5rem;
    }
    .select_field__value-container.select_field__value-container--is-multi.css-g1d714-ValueContainer {
      height: 5.5rem;
      align-items: baseline;
    }
  }
}
.vendor_assignments {
  max-height: 60vh;
  overflow-y: auto;
  padding-bottom: 6rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
.invite-dropdown {
  width: 18rem !important;
  margin-bottom: 1rem;
}
.refresh-btn {
  float: right;
  position: relative;
  right: 0;
}
.vendor_invite {
  button {
    width: 9.5rem;
    height: 3rem;
  }
  .vendor_tag {
    padding-top: 2rem;
    .region_label {
      position: relative;
      bottom: 0.5rem;
      left: 0.5rem;
    }
  }
  .tag_blocks {
    display: inline-block;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
  .tag_max_width {
    display: inline-block;
    max-width: 45rem;
  }
}

.invite_disable {
  cursor: not-allowed;
  opacity: 0.8;
  &:disabled {
    background-color: #d2d2d2;
  }
}

.update-vendor-disable {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  background-color: #dddddd !important;
}

.manually-assigned-vendor {
  color: #db735c !important;
  margin-bottom: 1rem;
}
.invite-tooltip {
  width: 15.313rem;
  height: 3.875rem;
  padding: 0;
}
div#invite.__react_component_tooltip.place-bottom::before,
div#invite.__react_component_tooltip.place-bottom::after {
  left: 90% !important;
}

div#invite.__react_component_tooltip.place-bottom {
  border-radius: 0.75rem !important;
}
//*************************** Manually Assignment ************************

.manually_assignment {
  position: relative;
  height: 90vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .dropdown_class {
    width: 18.75rem;
  }
  .assign_vendor {
    float: none;
    display: inline-flex;
    margin-left: 2rem;
  }
  .cancel_assign {
    padding-left: 2.3rem;
    color: #e89a89;
    cursor: pointer;
    display: table-cell;
  }
  .email_vendor_manual {
    height: 3rem;
    background-color: #ffffff;
    color: #e89a89;
    border: solid 1px #e89a89;
  }
  .sub-text-color {
    color: #529092;
  }
}
.img_label {
  position: relative;
  top: 4px;
}

.create-event-button {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

//******************** Event Layout *****************
.event-navbar-btn {
  position: absolute;
  bottom: 1.25rem;
  left: 1.5rem;
  color: #fff;
  text-decoration: underline;
}
.layout_wrapper {
  max-height: 100vh;
  .left_side {
    z-index: 1;
    width: 14.063rem;
    height: 100vh;
    background-color: #529092;
  }
  .ontime_hover {
    &:hover {
      text-decoration: underline;
    }
  }
  .label_color {
    color: #ffffff !important;
  }
  .lbl_count {
    color: #cad9da !important;
    line-height: 1;
  }
  .left_event_type {
    width: 10rem;
    height: 2.5rem;
    margin: 0.5rem 1rem;
    padding: 0 0.5rem 0 0;
    border-radius: 5px;
    background-color: #ffffff;
  }
  .left_edit_event {
    width: 9.063rem;
    height: 2.5rem;
    padding-top: 0.5rem;
  }
  .edit_schedule {
    border-radius: 10rem;
    background-color: #529092;
    border: 1px solid #ffffff;
    padding: 0.5rem 1rem;
    height: 2.5rem;
    img {
      padding-right: 9px !important;
    }
  }
  .subEvent_type {
    width: 0.625rem;
    height: 2.5rem;
    margin: 0 1.125rem 0 0;
    background-color: #52eac5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    float: left;
  }
  .eventType_text {
    line-height: 2.8;
    border-left-color: #52eac5;
    border-left-width: 0.625rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    font-size: 0.9rem;
    color: #787878;
  }
  .event_dropdown {
    width: 10rem;
    margin: 1rem;
  }
  .li_inactive {
    color: #ffffff;
    height: 3.25rem;
    padding: 1rem 0 1rem 1.5rem;
  }
  .li_active {
    background-color: #376c6d;
  }

  .event_cancel {
    width: 10rem;
    height: 1.938rem;
    margin: 0.875rem;
    padding: 0.188rem 1.25rem 0.375rem 0.625rem;
    border-radius: 5px;
    background-color: #bf2200;
    img {
      position: relative;
      bottom: 1px;
    }
  }

  .container {
    max-width: 80%;
    position: absolute;
    opacity: 1;
    height: 15vh;
    margin-top: 0 !important;
    border-bottom: none;
  }
  .cancel_message {
    transition: 0.5s ease;
    position: absolute;
    top: 30%;
    left: 45%;
    opacity: 1;
    z-index: 1;
    img {
      text-align: left;
      display: inline-block;
      height: 2rem;
    }
    .text {
      color: #bf2200;
      font-size: 1.5rem;
      background: no-repeat;
      display: inline-block;
      position: relative;
      top: 0.3rem;
    }
  }

  .cancel_event_overlay {
    opacity: 0.3;
    z-index: 0;
    pointer-events: none;
  }

  .cancel_publish {
    background-color: #dddddd !important;
    pointer-events: none;
  }
  .close-event-icon {
    position: relative;
    bottom: 1rem;
    right: 1rem;
  }
}

//********************** Footer Continue ***********************

.event_continue {
  width: 8.938rem !important;
  padding: 0.813rem 1.358rem 0.813rem 1.5rem !important;
  border-radius: 40px !important;
  border: solid 1px #e89a89 !important;
  background-color: #ffffff !important;
  color: #e89a89 !important;
}

//********************** Topbar ********************

.auto_save_text {
  position: relative;
  right: 3rem;
  bottom: 0.8rem;
}

.event-error-message {
  font-size: 1rem;
  text-align: center;
  color: #bf2200;
  padding-bottom: 0.751rem;
}
.event-category-space {
  padding-bottom: 0.613rem;
}
.timezone-space {
  padding-bottom: 0.613rem;
}
.event-category-without-error {
  padding-bottom: 1.613rem;
}
.selected_events_buildings_box {
  position: relative;
  min-height: 15rem;
  max-height: 26rem;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 1rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

.selected_buildings_box::-webkit-scrollbar {
  display: none;
}

.event_action__option--is-selected {
  background-color: #e89a89 !important;
  color: #3f3f3f !important;
}
//************ Task Management ****************
.task-pop-header {
  margin: 0 4.25rem 2.125rem 4.5rem;
}
.save_task {
  float: none !important;
  margin: 3rem 4.5rem;
}
.mx-18 {
  margin-left: 4.5rem;
  margin-right: 4.25rem;
}
.task-heading {
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f3f;
  height: 2.188rem;
  width: 100%;
}
.task-wrap {
  padding: 0.688rem 2.063rem 2.688rem 2.063rem;
}
.task-wrap::-webkit-scrollbar {
  display: none;
}
.inventory-wrap {
  padding: 0.688rem 2.063rem 2.688rem 2.063rem;
  max-height: 80vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
.pb-2 {
  padding-bottom: 0.4rem;
}
.p_timeOfEventTask {
  width: -webkit-fill-available;
  text-align: right;
  color: #646464;
}
.w-tasktime {
  width: 16.75rem;
  margin-right: 0.938rem;
}
.w-starttime {
  width: 10.375rem;
}
.h-tasklist {
  height: 75vh;
  &::-webkit-scrollbar {
    display: none;
  }
}
.add-task-btn {
  border-radius: 40px !important;
  border: solid 1px #e89a89 !important;
  background-color: #ffffff !important;
  color: #e89a89 !important;
}

.addtask-content {
  position: absolute;
  background-color: #fff;
  width: 14.188rem;
  height: 6.875rem;
  border-radius: 0.875rem;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e9e9e9;
  z-index: 1;
  top: 3rem;
}

.addtask-content li {
  color: #5d5d5d;
  padding: 12px 16px;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  &:first-child {
    border-top-left-radius: 0.875rem;
    border-top-right-radius: 0.875rem;
  }
  &:hover {
    background-color: #ffd3c9;
  }
}

//******************* RSVPs and Attendees *********************

.tab_margin {
  margin: 0 !important;
  border-bottom: none !important;
}
.user_rsvp_tab {
  width: 9.438rem;
  height: 4.375rem;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  background-color: #fcfcfc;
  font-weight: 500;
  text-align: left !important;
  padding: 0.5rem 1rem;
  margin-top: 0 !important;
  &:first-child {
    margin-left: 0 !important;
  }
}

.usertab_Active {
  background-color: #ffffff;
}
.rsvp_not_attended {
  font-size: 1rem !important;
  font-weight: 500;
}

.table_rsvp {
  width: 3rem;
}
.tbl_rating {
  width: 6rem;
}
.tbl_feedback {
  width: 20rem;
}
.events_row_loader {
  position: relative !important;
  top: 0.3rem !important;
  transform: none !important;
  left: 0 !important;
  display: inline-flex;
  width: auto;
  height: 2rem;
}
.no-vendor-assigned-label {
  color: #c2c2c2 !important;
  position: relative;
  top: 0.125rem;
}
////////////////////// Event Action Dropdown /////////////////////////
.eventaction-wrapper {
  width: 16.875rem;

  .select_field__control {
    width: 8.95rem;
    border-radius: 2rem !important;
    border: transparent !important;
    // border-color: #a9a9a9 !important;
  }
  .event-action.select_field__menu {
    margin-left: 2.6rem;
  }
  .select_field__menu,
  .select_field__menu-list {
    width: 14.188rem;
    border-radius: 0.7rem !important;
  }

  .select_field__option--is-focused {
    background-color: #ffe2de;
  }

  .select_field__value-container {
    opacity: 0;
  }

  .select_field__option--is-selected {
    background-color: #ffe2de;
  }
}
.custom-text-label {
  color: #3f3f3f !important;
  font-family: 'brandon-medium' !important;
}
//************ Broadcast user form ****************
.select-all-buildings {
  font-size: 0.875rem !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e89a89 !important;
  cursor: pointer;
}
.recepient-select {
  width: 24.938rem;
}
.broadcast-textarea {
  height: 9.375rem !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.recepient-select-field .select_field__option--is-disabled {
  color: #c2c2c2 !important;
}
.broadcast-building-field .select_field__value-container {
  height: 3rem !important;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.disable-field {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.8;
  background-color: #d2d2d2;
}
.add-task-disable {
  @extend .disable-field;
  &:disabled {
    background-color: #d2d2d2 !important;
  }
}
.manage-event-btn-disabled {
  &:disabled {
    background-color: #d2d2d2 !important;
    border: none !important;
    color: #ffffff !important;
  }
}

/////////////// Event task inventory //////////////
.inventory-table-wrapper {
  overflow: inherit !important;
}
.item-name {
  width: 12rem !important;
}
.item-location {
  width: 15rem !important;
}
.item-dropdown {
  width: 12rem !important;
  padding: 0 0.5rem !important;
}
.item-quantity {
  width: 1rem;
  padding-left: 3rem !important;
}
.dropdown-zindex {
  z-index: 99;
}
.assignToVendor {
  border: none;
  border-width: 0 !important;
}

.price_input .input_label_right{
  color: #bbbbbb !important;
  width: 3.5rem !important;
  background-color: #fff;
}