.user_section::-webkit-scrollbar {
  display: none;
}
.user_section {
  min-height: 90vh;
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}

.date_label_width {
  width: 16rem !important;
}
.ddl_label_width {
  width: 12rem !important;
}

.ddl_left_margin {
  margin-left: 1px;
}

.delete_user_popup {
  width: 32.813rem;
  height: 13.313rem;
  text-align: center;
}
.button_disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.8;
  &:disabled {
    background-color: #d2d2d2;
  }
}
