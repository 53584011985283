.priceList_wrapper {
  padding-top: 2rem;
  height: 80vh;
  padding-bottom: 4rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.price-list-item {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e8e8e8;
  border-radius: 1rem;
  padding: 1rem;
  line-height: 2rem;
  img {
    padding-bottom: 0.3rem;
  }
  .p_title {
    width: auto;
    color: #646464;
    font-size: 1.125rem;
    font-weight: 500;
  }
  .p_timeOfTask {
    width: 19rem;
    text-align: right;
    color: #7d7d7d;
  }
  .p_vendor {
    font-size: 0.938rem;
    color: #7d7d7d;
  }
  .p_description {
    font-size: 1rem;
    color: #7d7d7d;
  }
}
.add_price_list {
  border: solid 1px #e89a89;
  background-color: white !important;
  color: #e89a89 !important;
  float: none !important;
  margin: 0 1rem 2rem 2.5rem;
}
.h1_custom {
  height: 2.188rem;
  margin: 2rem 3rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #3f3f3f;
}
.add_price {
  float: none !important;
  margin: 0rem 3rem;
  width: 8.75rem !important;
  text-align: center;
  display: block !important;
}
.right_side_label {
  float: right;
  color: #a9a9a9 !important;
  font-weight: 500;
}
.pricing-form {
  height: 84vh;
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
 .range-text {
   font-family: 'brandon-medium' !important;
   font-size: 1rem;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   text-align: left;
   color: #5d5d5d;
 }
