.vendor-chat {
  max-height: 85vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  ul li::before {
    content: '•';
    color: #8ec310;
  }
}
