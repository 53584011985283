.create_group_btn {
  border: solid 1px #e89a89;
  background-color: white !important;
  color: #e89a89 !important;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem 0 2rem;
  padding: 0.438rem 0.875rem 0.438rem 0.83rem !important;
  height: 2rem !important;
  img {
    margin-right: 0.5rem;
  }
}

.btn-float-none {
  float: none !important;
  display: inline !important;
}

.location-btn-container {
  font-size: 0.875rem;
  margin: 0.2rem 0.5rem 0;
  width: 10rem;
  position: relative;
  left: 10rem;
  :nth-child(2) {
    background: none !important;
    font-size: 0.875rem;
    color: #5d5d5d;
  }
  :nth-child(1) {
    width: 3.438rem;
    height: 2rem;
    padding: 0.313rem 0.813rem 0.5rem 0.875rem;
    font-size: 0.875rem;
  }
}

//******************* item listing ******************
.tbl_col_width {
  width: 30rem;
}
.item_name {
  width: 20rem;
}
.p_desc {
  max-height: 3rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.edit_desc_input {
  border: none;
  padding: 0 !important;
  line-height: 1 !important;
}
.edit_description {
  padding: 0 1.25rem !important;
  color: #646464;
}
.delete_item {
  width: 4rem;
  padding: 0;
  background-color: white;
}

//************** Delete item popup **************

.itemlist_model {
  width: 31.813rem;
  height: 15.188rem;
}

.item_sub_header {
  margin-bottom: 1rem !important;
}
.item_close_button {
  margin-top: 0 !important;
}

//****************** Group Tab section *****************
.group_wrapper {
  position: relative;
  height: 80vh;
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 5rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
.group-name {
  padding-left: 0 !important;
}
//****************** Create Group popup ***************
.group_modal {
  width: 29.875rem;
  height: 19.563rem;
}
.header_group {
  position: relative;
  bottom: 1.8rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #3f3f3f;
}
.group_error {
  width: 23rem;
  text-align: center;
}
.mark_trunk {
  padding-top: 2rem;
  span {
    color: #646464;
    font-size: 1rem;
    position: relative;
    bottom: 0.5rem;
    left: 0.5rem;
  }
}

//************** Item Inventory modal ************
.inventory_modal {
  width: 39.813rem;
  height: 22.688rem;
  padding: 1.95rem 3.375rem;
  transform: translateY(20%);
}
.item_label {
  font-size: 1.125rem !important;
}
.add_item_button {
  width: 4.438rem;
  text-align: center;
  display: block !important;
  margin-top: 0.938rem;
}

//************** Whole application checkbox css ********************

input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  border: 1px solid #bbbbbb;
}

input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  border: 1px solid #bbbbbb;
  background-color: white;
  background-image: url(https://homh-site-media.s3.amazonaws.com/custom-uploads/tick.svg);
  background-repeat: no-repeat;
  background-size: 1rem 1.4rem;
  background-position: center;
}
