.building-section::-webkit-scrollbar {
  display: none;
}
.building-section {
  position: relative;
  height: 90vh;
  overflow-y: scroll;

  .save-changes {
    position: absolute;
    right: 0;
    top: 2rem;
  }
  .website_input_label {
    width: 20.925rem;
  }
  .error-message {
    position: absolute;
    font-size: 1rem;
    color: #bf2200;
    top: 40px;
  }

  .no-management {
    height: 1.375rem;
    margin: 0 0.5rem 0 0;
    font-size: 0.938rem;
    color: #5d5d5d;
  }
  .select-management {
    color: #a9a9a9;
    height: 2.313rem;
    margin: 0.813rem 8.25rem 0 0;
    font-size: 0.938rem;
  }
}

.de-activate {
  label {
    height: 1rem;
    color: #ee9d01;
    font-size: 1rem;
    margin: 5.5rem 57.625rem 0.25rem 0.063rem;
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
  span {
    font-size: 0.75rem;
    color: #a9a9a9;
  }
}

.delete_building_text {
  margin-bottom: 8rem;
  label {
    width: max-content;
    height: 1rem;
    color: #ff5f05;
    margin: 1.375rem 57.625rem 0.25rem 0.063rem;
    display: flex;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  span {
    font-size: 0.75rem;
    color: #a9a9a9;
  }
}

button:focus {
  outline: none !important;
}

.row_loader {
  position: absolute !important;
  top: auto !important;
  transform: none !important;
  left: 25% !important;
  display: inline-flex;
  width: 74rem;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
}
.users_row_loader {
  position: absolute !important;
  top: auto !important;
  transform: none !important;
  left: 35% !important;
  display: inline-flex;
  width: 65%;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
}
.disable-input {
  pointer-events: none;
  background-color: #fbfbfb !important;
}

.unit_company_label {
  width: 23rem !important;
}
.url-error-message {
  position: relative;
  font-size: 1rem;
  color: #bf2200;
}
