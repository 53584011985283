// For used in building add popup
.continue-button {
  margin-top: 1.875rem;
}
.error {
  position: absolute;
  font-size: 1rem;
  color: #bf2200;
}

// **************** Building Delete/Deactivate popup ****************
.delete_modal {
  width: 33.813rem;
  height: 18.5rem;
  text-align: center;
  position: relative;
  z-index: 10 !important;
}
.header_style {
  font-size: 1.25rem !important;
}
.sub_header_style {
  font-size: 1rem !important;
  margin: 1rem 1.5rem 0 1.5rem;
  display: flex;
}
.sub_header_child {
  @extend .sub_header_style;
  margin: 1rem 5.5rem 2rem 5.5rem;
}
.button_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete_building_button {
  text-align: center;
  display: block !important;
  width: 15.125rem !important;
}
.delete_building {
  margin: 1rem 2.5rem 1rem 3.5rem;
}
