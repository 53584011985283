.create_group_btn {
  border: solid 1px #e89a89;
  background-color: white !important;
  color: #e89a89 !important;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem 0 2rem;
  padding: 0.438rem 0.875rem 0.438rem 0.83rem !important;
  height: 2rem !important;
  img {
    margin-right: 0.5rem;
  }
}

.btn-float-none {
  float: none !important;
  display: inline !important;
}

.location-btn-container {
  font-size: 0.875rem;
  margin: 0.2rem 0.5rem 0;
  width: 10rem;
  position: relative;
  left: 10rem;
  :nth-child(2) {
    background: none !important;
    font-size: 0.875rem;
    color: #5d5d5d;
  }
  :nth-child(1) {
    width: 3.438rem;
    height: 2rem;
    padding: 0.313rem 0.813rem 0.5rem 0.875rem;
    font-size: 0.875rem;
  }
}

//******************* item listing ******************
.tbl_col_width {
  width: 30rem;
}
.item_name {
  width: 20rem;
}
.p_desc {
  max-height: 3rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.edit_desc_input {
  border: none;
  padding: 0 !important;
  line-height: 1 !important;
}
.edit_description {
  padding: 0 1.25rem !important;
  color: #646464;
}
.delete_item {
  width: 4rem;
  padding: 0;
  background-color: white;
}

//************** Delete item popup **************

.itemlist_model {
  width: 31.813rem;
  height: 15.188rem;
}

.item_sub_header {
  margin-bottom: 1rem !important;
}
.item_close_button {
  margin-top: 0 !important;
}

.delete-modal {
  width: 29.875rem;
  height: 16.938rem;
}
.add-vendors-modal {
  width: 29.875rem;
  height: 18.938rem;
}

//****************** Group Tasks section *****************
.dragTask {
  margin: 0 !important;
  padding: 0 !important;
}
.taskgroup-wrapper {
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 5rem;
  &::-webkit-scrollbar {
    display: none;
  }
  .allservice-elements {
    .select_field__value-container.select_field__value-container--is-multi.css-g1d714-ValueContainer {
      min-height: 5.5rem;
      align-items: baseline;
    }
  }
}
.grouptask-item {
  border: solid 1px #ebebeb;
  padding: 1rem;
  line-height: 2rem;
  cursor: pointer;
  border-radius: 0.8rem;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  .p_title {
    width: -webkit-fill-available;
    color: #646464;
    font-size: 1.125rem;
  }
  .p_timeOfTask {
    width: -webkit-fill-available;
    text-align: right;
    color: #646464;
  }
  .p_vendor {
    font-size: 0.938rem;
    color: #646464;
  }
  .p_description {
    font-size: 1rem;
    color: #646464;
  }
  .a_link {
    font-size: 0.938rem;
    color: #646464;
  }
  .a_link:hover {
    text-decoration: underline;
    color: #e89a89;
  }
}
//****************** Create Group popup ***************
.group-modal {
  width: 29.875rem;
  height: 20.313rem;
  transform: translateY(10%);
}
.group-modal-after {
  height: 34.25rem;
}
.group-header {
  position: relative;
  bottom: 1.8rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #3f3f3f;
}
.group-button {
  &:disabled {
    background-color: #d2d2d2;
  }
}
.header-hr {
  position: relative;
  top: -1rem;
  width: inherit;
  left: -3.37rem;
}

.task-radio {
  padding-top: 0.5rem;
  label {
    color: #5d5d5d;
    font-size: 0.875rem;
    position: relative;
    bottom: 0.25rem;
    left: 0.5rem;
  }
}

.serviceToggle-tooltip {
  width: 22rem;
  height: 5rem;
  border-radius: 1rem !important;
}
div#serviceToggle.__react_component_tooltip.place-bottom::before,
div#serviceToggle.__react_component_tooltip.place-bottom::after {
  left: 85% !important;
}

//************** Delete modal ************
.continue-button {
  width: 8.25rem !important;
  border-radius: 2.5rem !important;
  color: white !important;
}
.close-button {
  @extend .continue-button;
  color: #e89a89 !important;
}

//************** Create Group checkbox css ********************
input[type='radio'] {
  -webkit-appearance: none;
  position: relative;
  width: 1rem;
  height: 1rem;
  bottom: 0.15rem;
  border: 1px solid #5d5d5d;
  border-radius: 50%;
  outline: none;
}

input[type='radio']:before {
  content: '';
  display: inline-block;
  width: 0.62rem;
  height: 0.62rem;
  position: absolute;
  bottom: 0.1rem;
  left: 0.13rem;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: #5d5d5d;
}
.add-all-text {
  margin-left: 240px;
  color: #e89a89;
}
.manage-group-wrapper {
  //z-index: 1 !important;
}
