// ***************** Add New space modal css *******************
.add-block-section::-webkit-scrollbar {
  display: none;
}
.add-block-section {
  position: relative;
  min-height: 90vh;
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 2rem;
  .save-changes {
    position: absolute;
    top: 1rem;
    right: 0;
  }
}
.add-company-modal {
  height: 22.438rem;
}
.transform-modal {
  transform: translateY(17%) !important;
}

// For used in building add popup
.modal-height {
  height: 23rem;
  transform: translateY(17%) !important;
}
.textarea-popup {
  height: 12.688rem !important;
  margin: 0;
}
.save-button {
  width: 6.188rem !important;
  text-align: center;
  display: block !important;
  margin-top: 0.938rem;
}
.error {
  position: absolute;
  font-size: 1rem;
  text-align: left;
  color: #bf2200;
}
.building-dropdown {
  width: 33rem !important;
}
.block-count {
  font-size: 1.125rem !important;
  font-weight: bolder;
}
.company-header {
  float: right;
  span {
    padding-left: 31rem;
    font-size: 1rem;
    font-weight: 500;
    color: #e89a89;
    cursor: pointer;
    text-decoration: underline;
  }
}
.delete-company {
  color: #e89a89;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
}
.company_error {
  position: relative;
  font-size: 1rem;
  text-align: left;
  color: #bf2200;
}
