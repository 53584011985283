// *************** Add New Management from Building ******************* //

.management-section {
  .save-changes {
    position: absolute;
    top: 7rem;
    right: 0;
    margin-right: 2.5rem;
  }
}
.navigation-section {
  display: inline-block;
  .navigation-link {
    height: 1rem;
    margin: 0 0.813rem 0 0;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: left;
    color: #5d5d5d;
    text-decoration: underline;
  }
  .sub-navigation-header {
    display: inline-flex;
    font-size: 0.875rem;
    color: #5d5d5d;
  }
  span {
    margin: 0 0.813rem 0 0;
    color: #5d5d5d;
    font-size: 0.875rem;
  }
}
.error-message {
  position: absolute;
  font-size: 1rem;
  color: #bf2200;
  top: 40px;
}
.error_management {
  @extend .error-message;
  top: 17%;
}
.anchor {
  span {
    max-width: 14.5625rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
 }
