.navlinks {
  color: #fff;
  cursor: pointer;
}
.select-sub-menu {
  background-color: #376c6d;
}
.select-menu {
  background-color: #376c6d;
}
.menuPadding {
  padding: 1.25rem 1.25rem 1.18rem 1rem;
}
.subMenuPadding {
  padding: 0.7rem 2rem;
  font-size: 0.938rem;
}
.subMenuPadding:hover {
  background-color: #478183;
}
.menuPadding:hover {
  background-color: #478183;
}
