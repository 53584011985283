.nested-sidebar {
  height: 100vh;
  background: #529092;
  width: 10.313rem;
}
.table-wrapper {
  position: relative;
  min-height: 90vh;
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 2rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
