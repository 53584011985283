//***************************  TimeLine Feed ************************
.dot {
  height: 3.75rem;
  width: 3.75rem;
  padding: 1.281rem 1.156rem 1.344rem 1.156rem;
  margin: 2.188rem 2.125rem 0 4.563rem;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  position: relative;
}
.dot:first-child {
  margin: 2.188rem 2.125rem 0 0.99rem;
}
.progress-line {
  margin-top: 3.844rem;
  margin-left: 1.111rem;
  height: 0.438rem;
  background-color: #dddddd;
  position: absolute;
}
.invite-sent {
  width: 34.78rem;
}
.invite-not-sent {
  width: 23.78rem;
}
.progress--percentage {
  height: 100%;
  background-color: #529092;
}
.timeline-heading {
  margin-top: 1.025rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #5d5d5d;
  height: 2.75rem;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}
.timeline-stage-done {
  background-color: #529092;
}
.timeline-stage-not-done {
  background-color: #dddddd;
}
.progress-line-headings {
  font-size: 1rem;
  color: #646464;
}
.line-text {
  margin-left: 2.938rem;
  color: #646464;
}
.line-text:first-child {
  margin-left: 0;
}
.line-sub-heading-not-complete {
  color: #c2c2c2;
}
.line-sub-heading-complete {
  color: #646464;
}
.timeline-table-heading {
  color: #5d5d5d;
}
.time-line-date {
  color: #5c5c5c;
  width: 4rem;
}
.time-line-time {
  color: #a9a9a9;
  white-space: nowrap !important;
}
.timeline-table {
  margin-left: 1.56rem;
  width: 93%;
  td {
    padding: 0.31rem 1.25rem !important;
  }
  thead {
    display: none;
  }
}
.sub-text-color {
  color: #529092;
}
.main-text-color {
  color: #5c5c5c;
}
.header-line {
  height: 0.063rem;
  background-color: #f3f3f3;
}
.header-first-line {
  height: 0;
}
.timeline-heading-2 {
  margin-top: 1.025rem;
  color: #646464;
}
.timeline_feed {
  position: relative;
  height: 77.5vh;
  min-height: 77.5vh;
  overflow-y: scroll;
  .table-section .table .table-cell:first-child {
    width: 6rem;
  }
}
.timeline_feed::-webkit-scrollbar {
  display: none;
}

.feed-table {
  .margin-bottom {
    margin-bottom: 0;
  }
}
