.add-cluster-modal {
  height: 28.813rem;
  transform: translateY(10%);
}
.label {
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: 500;
  color: #5d5d5d;
  position: relative;
  bottom: 20px;
}
.control-space {
  margin-bottom: 1.613rem;
}
.input-control-space {
  margin-bottom: 1.613rem;
}
.cluster-error {
  position: absolute;
  font-size: 1rem;
  color: #bf2200;
  top: 50px;
  text-align: center;
  display: block;
  width: 90%;
}
.cluster_col_last {
  width: 9rem;
  padding-left: 3rem;
  background-color: white;
  color: #646464;
}
.mgmnt_label {
  width: 21rem !important;
}
.cluster_profile::-webkit-scrollbar {
  display: none;
}
.cluster_profile {
  max-height: 90vh;
  overflow-y: scroll;

  .save_cluster {
    position: relative;
    top: 0;
    right: 0;
  }

  .table_cell_padding {
    padding: 1rem;
    width: 2rem;
    padding-bottom: 0.6rem;
    background-color: white;
  }
  .delete_cluster {
    margin-top: 8rem;
    margin-bottom: 6rem;
    label {
      height: 1rem;
      color: #ff5f05;
      margin: 1.375rem 57.625rem 0.25rem 0.063rem;
      display: flex;
      font-size: 1rem;
      &:hover {
        cursor: pointer;
      }
    }
    span {
      font-size: 0.75rem;
      color: #a9a9a9;
    }
  }
  .edit_cluster {
    display: inline-flex;
    float: right;
  }
}
.index_row {
  padding-left: 0.5rem;
}
.table_checkbox {
  background: white;
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
  color: #7d7d7d;
  height: 1.5rem;
}

.basic_title {
  position: relative;
  top: 20px;
  width: 50%;
}