.side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 63%;
  z-index: 2 !important;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 2%;
  border-bottom-left-radius: 2%;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
.side-drawer.open {
  transform: translateX(0);
  transition: transform 0.3s ease-in;
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1 !important;
  top: 0;
  right: 0;
}
.side-drawer-event-width {
  width: 70.375rem !important;
  padding-top: 1.563rem;
  padding-right: 1.625rem;
}
.side-drawer-event-mng-width {
  width: 74.5% !important;
  padding-top: 1.563rem;
  padding-right: 1.625rem;
}
.side-drawer-create-group-width {
  width: 40% !important;
  border-radius: 0;
  padding: 1.938rem 2.5rem 4.438rem 2.438rem;
}
.new-task-side-drawer {
  z-index: 3 !important;
}
