.report-modal {
  width: 50rem;
  height: 25.5rem;
  transform: translateY(10%);
}
.report-wrapper {
  .report-title {
    font-size: 1.375rem;
    bottom: 0;
    margin-bottom: 2rem;
  }
}
.vendor-category-select-ddl__control {
  border: solid 1px #bbb !important;
}
.vendor-category-select-ddl__control:hover {
  border: solid 1px #bbb;
}
.vendor-category-select-ddl__menu-list::-webkit-scrollbar {
  display: none;
}
