.vendor-section::-webkit-scrollbar {
  display: none;
}
.vendor-section {
  position: relative;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
  .region-field,
  .region-field .select_field_with_label__control {
    height: 6.5rem !important;
  }
  .region-field .select_field_with_label__control {
    overflow: hidden;
    overflow-y: scroll;
  }
  .region-field .select_field_with_label__control::-webkit-scrollbar {
    display: none;
  }
  .save-changes {
    position: absolute;
    right: 0;
    top: 2rem;
  }
  .website_input_label {
    width: 20.925rem;
  }
  .error-message {
    position: absolute;
    font-size: 1rem;
    color: #bf2200;
    top: 40px;
  }

  .no-management {
    height: 1.375rem;
    margin: 0 0.5rem 0 0;
    font-size: 0.938rem;
    color: #5d5d5d;
  }
  .input-height {
    height: auto !important;
    min-height: 3rem;
  }
  .radio_label {
    margin-right: 1rem !important;
  }
}
.btn-position {
  position: relative;
  right: 4.865rem;
}
.service_input_width {
  width: 12rem !important;
}
.associated_building {
  width: 18rem !important;
}

.de-activate {
  label {
    width: max-content;
    height: 1rem;
    color: #ee9d01;
    font-size: 1rem;
    margin: 5.5rem 57.625rem 0.25rem 0.063rem;
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
  span {
    font-size: 0.75rem;
    color: #a9a9a9;
  }
}

.delete_building_text {
  margin-bottom: 8rem;
  label {
    width: max-content;
    height: 1rem;
    color: #ff5f05;
    margin: 1.375rem 57.625rem 0.25rem 0.063rem;
    display: flex;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  span {
    font-size: 0.75rem;
    color: #a9a9a9;
  }
}

.row_loader {
  position: absolute !important;
  top: auto !important;
  transform: none !important;
  left: 25% !important;
  display: inline-flex;
  width: 74rem;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
}

.add-vendor-modal {
  width: 30.188rem !important;
  height: 22.688rem !important;
  transform: translateY(20%) !important;
  padding: 2.935rem 2.375rem !important;
}

.individual_vendor {
  width: 12.5rem;
  height: 3rem;
  padding: 0.75rem 0.313rem 0.75rem 0.625rem;
  color: #5d5d5d;
  border-radius: 10px;
  border: solid 1px #ebebeb;
  display: inline-flex;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    border: solid 1px #dfba60;
    background-color: #fff3d6;
  }
  img {
    display: inline-flex;
    height: 1.5rem;
    padding-right: 10px;
  }
}
.active {
  outline: none;
  border: solid 1px #dfba60;
  background-color: #fff3d6;
}

//********************* Tab Css *********************

.container {
  position: relative;
  width: 400px;
  overflow: hidden;
  border-bottom: 1px solid #f6f6f6;
}
.label-container {
  height: 4rem;
}
.tab_label {
  width: 80px;
  height: 3.5rem;
  padding-top: 0.75rem;
  transition: all 0.5s;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  box-sizing: border-box;
  line-height: 2.1;
  margin: 10px;
  bottom: 0 !important;
}

.tab {
  display: none;
}

.tab:nth-of-type(1):checked ~ .label-container .label:nth-of-type(1),
.tab:nth-of-type(2):checked ~ .label-container .label:nth-of-type(2) {
  color: #646464;
  opacity: 1;
}

.tab:nth-of-type(1) ~ .label-container .label:nth-of-type(1),
.tab:nth-of-type(2) ~ .label-container .label:nth-of-type(2) {
  color: #c2c2c2;
}

//********************* Add Associated building page *********************

.add-associated::-webkit-scrollbar {
  display: none;
}
.add-associated {
  height: 88vh;
  overflow-y: scroll;
  padding-top: 1rem;
}
.navigation__link {
  position: relative;
  top: -2rem;
  left: -5rem;
}
.vendor_listing {
  position: relative;
  height: 80vh;
  overflow-y: auto;
  padding-bottom: 4rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
.tag-vendor-section {
  position: relative;
  height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

//********************** Common Layout ****************
.main_wrapper {
  max-height: 100vh;
  overflow: hidden;
}
