.custom_publish_button {
  margin: 0 0 0 3.5rem;
  padding: 0.313rem 0.938rem 0.5rem 0.938rem;
  border-radius: 40px;
  background-color: #529092;
  color: #ffffff;
  font-size: 0.875rem;
}
.custom_vendor_status_cell {
  width: 8rem;
  padding-left: 1rem;
  color: #5d5d5d;
  font-family: 'brandon-regular';
}
.custom-event-name {
  display: table-cell;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  padding: 1.31rem 1.25rem !important;
}
.pending_color {
  color: #bf2200 !important;
}
.event-section {
  height: auto;
  max-height: 89vh;
  overflow-y: auto;
  padding-bottom: 8rem !important;
  &::-webkit-scrollbar {
    display: none;
  }
}
.event-section-aftersearch {
  @extend .event-section;
  max-height: 80vh;
}

.table-date-col {
  width: 5rem !important;
  padding-right: 0 !important;
}
.table-time-col {
  width: 8rem !important;
  padding-left: 0 !important;
}
.table-img-draft {
  width: 4rem !important;
}
.table-img {
  width: 3rem !important;
  padding-right: 0 !important;
}
.event-listing-section {
  @extend .event-section;
  padding-bottom: 8rem !important;
}
.listing-wrapper {
  .btn-publish {
    width: 4.5rem;
    height: 2rem;
    border-radius: 40px;
    background-color: #529092;
    display: block;
    padding: 0;
    font-size: 0.875rem;
    margin-right: 1rem;
  }
  .header-cell {
    &:last-child {
      display: none;
    }
  }
  .last-cell {
    width: 4rem;
    padding: 0 !important;
  }
  .btn-publish-disabled {
    @extend .btn-publish;
    background-color: #ebebeb;
    cursor: not-allowed;
  }
  .super-hero-column {
    display: block !important;
  }
}
.calendar-body .text-Color_2:hover {
  color: #ffffff;
}

.table-booking {
  padding: 0 !important;
}
.search-section .event_action__control {
  padding-left: 1rem;
}
.header-cell {
  padding-right: 0 !important;
}
.search-context {
  width: 100%;
  height: 4.25rem;
  flex-grow: 0;
  padding: 0.625rem 66.625rem 0.875rem 2.375rem;
  background-color: #fbfbfb;
  display: grid;
  .searching-in-message,
  .cancel-search {
    font-family: 'brandon-regular';
    font-size: 0.813rem;
    color: #5d5d5d;
  }
  .searching-for {
    font-family: 'brandon-medium';
    font-size: 1.25rem;
    font-weight: 500;
    color: #3f3f3f;
  }
}
.profile_action__menu {
  min-width: max-content !important;
  right: 0.5rem !important;
}
.profile_action__option {
  padding: 1rem !important;
}

///////////////// Calendar //////////////////
.date-selected {
  background: #e89a89 !important;
  color: #ffffff !important;
  .event-dot {
    background-color: #ffffff !important;
  }
}
.date-hover {
  &:hover {
    @extend .date-selected;
  }
}
// Category search css
.region-modal {
  width: 27.125rem;
  transform: translateY(10%);
}
.region-filter-ddl {
  width: 6.875rem;
}
.status-filter-ddl {
  width: fit-content;
}
.region-search-input {
  width: 12.688rem !important;
  height: 2.5rem !important;
  flex-grow: 0;
  padding: 0.5rem 4.75rem 0.625rem 1.125rem !important;
  border-radius: 40px !important;
  border: solid 1px #bbb !important;
  background-color: #fff !important;
}
.region-items::-webkit-scrollbar {
  display: none;
}
.hr-border {
  border-top-width: 1px;
  border-top-color: #f5f5f5;
  margin-bottom: 1rem;
}
.category-select-ddl__menu-list::-webkit-scrollbar {
  display: none;
}
.region-text,
.vendor-status-text {
  padding-bottom: 0.19rem;
  padding-right: 0 !important;
}
.category-select-ddl__single-value {
  padding-bottom: 0.19rem;
  color: #5d5d5d !important;
}
.filter-action-div {
  height: 3.6rem;
  .save-filter {
    margin-bottom: 0.5rem;
  }
  .clear-btn {
    padding-top: 0 !important;
  }
}
.category-select-ddl__menu-list {
  max-height: 16rem !important;
}

.select-region-popup {
  .clear-btn {
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
  .save-btn {
    height: 2.5rem !important;
  }
}
.event-listing-tab {
  span:first-child {
    margin-left: 0 !important;
  }
}
